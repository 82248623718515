import React from "react"
import Base from "./Base"

import ComponentParser from "../helpers/ComponentParser"

// import contentParser from 'gatsby-source-wpgraphql-images'

// import { pluginOptions } from "../helpers/helpers"

// const wordPressUrl = pluginOptions.wordPressUrl
// const uploadsUrl = pluginOptions.uploadsUrl

const CaseStudyTemplate = ({ pageContext }) => {
  const {contentFiles, contentParsed} = pageContext
  
  return (
    <Base context={pageContext}>
      {/* {<section className="section-bottom"><div className="inner-div"><div className="case-study-content">{contentParser({ content: contentParsed, files: contentFiles }, { wordPressUrl, uploadsUrl })}</div></div></section>} */}
      { <ComponentParser key={pageContext} content={pageContext.blocks} contentParsed={contentParsed} files={contentFiles} />}
    </Base>
  )
} 

export default CaseStudyTemplate